import moment from "moment"
import { requests, superagent, API_ROOT } from "./BaseUrl"
import axios from "./interceptor-middleware";
//const url = '/memberGroups/';

const SchemeModel = {
  getScheme() {
    return axios.get(`${API_ROOT}/scheme/entry`)
  },
  saveScheme(data) {
    return axios.post(`${API_ROOT}/scheme/entry`, data)
  },
  deleteScheme(id) {
    return axios.delete(`${API_ROOT}/scheme/entry/${id}`)
  },
  getLoanAppInitialData() {
    return [axios.get(`${API_ROOT}/scheme/entry`), axios.get(`${API_ROOT}/member/entry/all`)]
  },
  getScheme() {
    return axios.get(`${API_ROOT}/scheme/entry`)
  },
  getMembers() {
    return axios.get(`${API_ROOT}/member/entry/all`)
  }


}
const GroupLoanModel = {
  applyForloan(data) {
    return axios.post(`${API_ROOT}/groupLoan/applyGroupLoan`, data)
  },
  getApprovalList() {
    return axios.post(`${API_ROOT}/groupLoan/entry`, { data: "pendingApproval" });
  },
  getDisburseList() {
    return axios.post(`${API_ROOT}/groupLoan/entry`, { data: "pendingDisburse" });
  },
  getLoanDetailbyId(id) {
    return axios.post(`${API_ROOT}/groupLoan/entry`, { data: id });
  },
  approveRejectLoan(data) {
    return axios.post(`${API_ROOT}/groupLoan/approveLoan`, data)
  },
  getAllLoanApplications(payload) {
    return axios.post(`${API_ROOT}/groupLoan/entry/`, payload);
  },
  disburseRejectLoan(data) {
    return axios.post(`${API_ROOT}/groupLoan/disburseLoan`, data)
  },
  getSattlementAmount(loan_account_no) {
    return axios.get(`${API_ROOT}/closeAccount/calculateSattleAmount/${loan_account_no}`)
  },
  closeAccount(data) {
    return axios.post(`${API_ROOT}/closeAccount/closeLoanAccount`, data)
  },
  downloadLoanExcel() {
    return axios.get(`${API_ROOT}/groupLoan/downloadActiveLoansExcel`)
  },
  loanVillageMap() {
    return axios.get(`${API_ROOT}/groupLoan/loanVillageMap`);
  },
  updateloan(data, id) {
    return axios.put(`${API_ROOT}/groupLoan/updateLoan/${id}`, data)
  },
  addInvestorInLoan(data) {
    return axios.put(`${API_ROOT}/groupLoan/addInvestorInLoan/`, data)
  },
  loanSanction(data) {
    return axios.post(`${API_ROOT}/groupLoan/loanSanction`, data)
  },
  getSanctionedDisbursedLoan() {
    return axios.get(`${API_ROOT}/groupLoan/getLoanNumber`)
  },
  getLoanCashCounter() {
    return axios.post(`${API_ROOT}/groupLoan/entry`, { data: "pendingCashCounter" });
  },
  loanAmountPay(data) {
    return axios.post(`${API_ROOT}/groupLoan/loanAmountPayment`, data)
  },
  getClosedAccount(investor_id = "all") {
    return axios.get(`${API_ROOT}/closeAccount/entry/${investor_id}`);
  },
  getClosedAccountBetweenDates(startDate = "null", endDate = "null") {
    return axios.get(`${API_ROOT}/closeAccount/entryBetweenDates/${startDate}/${endDate}`);
  },
  loanDeleteRequest(id) {
    return axios.put(`${API_ROOT}/groupLoan/loanDeleteRequest/${id}`);
  },
  getPendingDeleteApproval() {
    return axios.get(`${API_ROOT}/groupLoan/getPendingDeletion`);
  },
  deleteLoan(data) {
    return axios.post(`${API_ROOT}/groupLoan/deleteLoan`, data)
  },
  sanctionBackLoan(data) {
    return axios.post(`${API_ROOT}/groupLoan/sanctionBackLoan`, data)
  },
  jointLiabilityData(groupCode) {
    return axios.get(`${API_ROOT}/groupLoan/jointLibalities/${groupCode}`);
  },
  getAccountLastEmiDaysDiff(payload) {
    return axios.post(`${API_ROOT}/groupLoan/account_last_emi_deposit_days_diff/`, payload);
  },
  excelAccountLastEmiDaysDiff(payload) {
    return axios.post(`${API_ROOT}/groupLoan/excel_account_last_emi_deposit_days_diff/`, payload);
  },
  getAllActiveLoanApplications(payload) {
    return axios.post(`${API_ROOT}/groupLoan/getActiveLoansList/`, payload);
  },
  getAllClosedLoanApplications(payload) {
    return axios.post(`${API_ROOT}/groupLoan/getClosedLoansList/`, payload);
  },
  getAllAccountSattlementAmt() {
    return axios.get(`${API_ROOT}/closeAccount/allAccountSattlementAmt`);
  },

}
const EmiModel = {
  getEmiHistory(loanAccountNo) {
    return axios.get(`${API_ROOT}/emis/emiHistoryByAccount/${loanAccountNo}`);
  },
  calculateEMI(data) {
    return axios.post(`${API_ROOT}/emis/calculateEMI`, data);
  },
  getDueEmis(today = moment().format("yyyy-MM-DD")) {
    return axios.get(`${API_ROOT}/emis/dueEMIs/${today}`);
  },
  getRepayments(payload) {
    return axios.post(`${API_ROOT}/emis/getRepayments/`, payload);
  },
  paidEmi(data) {
    return axios.put(`${API_ROOT}/emis/entry`, data);
  },
  getPaidEmis(loanAccountNo) {
    return axios.get(`${API_ROOT}/emis/entry/${loanAccountNo}`);
  },
  getEmisByLoanAccount(loanAccountNo) {
    return axios.get(`${API_ROOT}/emis/emiByLoanAccount/${loanAccountNo}`);
  },
  getAllEmis(today = moment().format("yyyy-MM-DD"), branch_code = "", month = "") {
    return axios.get(`${API_ROOT}/emis/allEmis/${today}?branch=${branch_code}&month=${month}`);
  },
  getPendingEmis(collectorCode = "all") {
    return axios.get(`${API_ROOT}/emis/pendingApprovalEmis/${collectorCode}`);
  },
  approveEmi(id, status, loan_account_no) {
    return axios.put(`${API_ROOT}/emis/approveRejectEmi`, { "id": id, "is_approved": status, "loan_account_no": loan_account_no });
  },
  getPendingEmisCount() {
    return axios.get(`${API_ROOT}/emis/countPendingEmis`);
  },
  getCollectionSheet(startDate, endDate, offset) {
    return axios.get(`${API_ROOT}/emis/getCollectionSheet/${startDate}/${endDate}/${offset}`);
  },
  downLoadCollectionSheetExcel(startDate, endDate) {
    return axios.get(`${API_ROOT}/emis/exportCollectionSheet/${startDate}/${endDate}`);
  },
  getAdvanceAmountByLoan(loan_account_no) {
    return axios.get(`${API_ROOT}/emis/getAdvanceDeposited/${loan_account_no}`);
  },
  saveAdvanceAmount(payload) {
    return axios.post(`${API_ROOT}/emis/depositAdvanceEmi`, payload);
  },
  getOverdues(payload) {
    return axios.post(`${API_ROOT}/emis/overdueLoans/`, payload);
  },
  getOverduesExcel(payload) {
    return axios.post(`${API_ROOT}/emis/overdueLoansExcel`, payload);
  },
  getDueEmiLoanAccountNoByGroup(payload) {
    return axios.post(`${API_ROOT}/emis/getDueEmiLoanAccountNoByGroup`, payload);
  },
  payEmi(data) {
    return axios.post(`${API_ROOT}/emis/payEMI`, data);
  },
  getPartialEmisHostory(loan_account_no) {
    return axios.get(`${API_ROOT}/emis/getPartialEmisHistory/${loan_account_no}`);
  },
  payPartialEmi(payload) {
    return axios.post(`${API_ROOT}/emis/payPartialEMI`, payload);
  },
  getPendingApprovalPartialEmi() {
    return axios.get(`${API_ROOT}/emis/pendingApprovalPartialEmis/all`);
  },
  approveRejectpartialEmi(data) {
    return axios.post(`${API_ROOT}/emis/approveRejectPartialEmi`, data);
  }

}
export default { SchemeModel, GroupLoanModel, EmiModel };