import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import InnerLayout from './components/layout/InnerLayout';
import ROUTE from './constants/routes';

// Lazy load components
const LandingPage = lazy(() => import('./components/dashboard/LandingPage'));
const Dashboard = lazy(() => import('./components/dashboard/Dashboard'));
const InvestorDashboard = lazy(() => import('./components/dashboard/InvestorDashboard'));
const MemberGroup = lazy(() => import('./components/masterRecord/memberGroup/MemberGroup'));
const AddMemberGroup = lazy(() => import('./components/masterRecord/memberGroup/AddMemberGroup'));
const AddMember = lazy(() => import('./components/masterRecord/member/AddMember'));
const Scheme = lazy(() => import('./components/groupLoan/Scheme'));
const MemberViewDetails = lazy(() => import('./components/masterRecord/member/MemberViewDetails'));
const AddScheme = lazy(() => import('./components/groupLoan/AddScheme'));
const LoanApplication = lazy(() => import('./components/groupLoan/LoanApplication'));
const LoanApproval = lazy(() => import('./components/groupLoan/LoanApproval'));
const DisburseLoan = lazy(() => import('./components/groupLoan/DisburseLoan'));
const LoanApprovalDetails = lazy(() => import('./components/groupLoan/LoanApprovalDetails'));
const AllApplications = lazy(() => import('./components/groupLoan/AllApplications'));
const EmiCalculator = lazy(() => import('./components/groupLoan/EmiCalculator'));
const DueEmis = lazy(() => import('./components/emis/DueEmis'));
const PaidDetailView = lazy(() => import('./components/dashboard/PaidDetailView'));
const RecievedDetailView = lazy(() => import('./components/dashboard/RecievedDetailView'));
const ProcessingFee = lazy(() => import('./components/processing-fee/ProcessingFee'));
const Profit = lazy(() => import('./components/profit/Profit'));
const AddProcessingFee = lazy(() => import('./components/processing-fee/AddProcessingFee'));
const OutstandingDetailView = lazy(() => import('./components/dashboard/OutstandingDetailView'));
const Expense = lazy(() => import('./components/expenses/Expense'));
const AddExpense = lazy(() => import('./components/expenses/AddExpense'));
const ListUser = lazy(() => import('./components/users/ListUser'));
const AddUser = lazy(() => import('./components/users/AddUser'));
const Village = lazy(() => import('./components/village/Village'));
const AddVillage = lazy(() => import('./components/village/AddVillage'));
const MemberVillageMap = lazy(() => import('./components/village/MemberVillageMap'));
const EmiApproval = lazy(() => import('./components/approvals/EmiApproval'));
const UserMenus = lazy(() => import('./components/users/UserMenus'));
const EditScheme = lazy(() => import('./components/modification/EditScheme'));
const EditMember = lazy(() => import('./components/modification/EditMember'));
const EditMemberGroup = lazy(() => import('./components/modification/EditMemberGroup'));
const EditExpense = lazy(() => import('./components/modification/EditExpense'));
const EditLoanApplication = lazy(() => import('./components/modification/EditLoanApplication'));
const EditVillage = lazy(() => import('./components/modification/EditVillage'));
const EditProcessingFee = lazy(() => import('./components/modification/EditProcessingFee'));
const PrintLoan = lazy(() => import('./components/groupLoan/PrintLoan'));
const SanctionLatter = lazy(() => import('./components/print/SancationLatter'));
const DownloadDoc = lazy(() => import('./components/print/DownloadDoc'));
const OpenSavingAccount = lazy(() => import('./components/savingAccount/OpenSavingAccount'));
const SavingAccountReport = lazy(() => import('./components/savingAccount/SavingAccountReport'));
const SavingAccountDepositedReport = lazy(() => import('./components/savingAccount/SavingAccountDepositedReport'));
const PassbookPdf = lazy(() => import('./components/savingAccount/PassbookPdf'));
const AddTransaction = lazy(() => import('./components/savingAccount/AddTransaction'));
const LoanCashAcTransfer = lazy(() => import('./components/groupLoan/LoanCashAcTransfer'));
const CollectionSheet = lazy(() => import('./components/emis/CollectionSheet'));
const AdminInvestorDashboard = lazy(() => import('./components/dashboard/AdminInvestorDashboard'));
const MemberApproval = lazy(() => import('./components/approvals/MemberApproval'));
const AdminMainLedgerDetail = lazy(() => import('./components/dashboard/AdminMainLedgerDetail'));
const ProfitLedgerDetails = lazy(() => import('./components/dashboard/ProfitLedgerDetails'));
const MainLedgerDetailInvestor = lazy(() => import('./components/dashboard/MainLedgerDetailInvestor'));
const CompanyExpenseSlipPdf = lazy(() => import('./components/print/CompanyExpenseSlipPdf'));
const LoanActions = lazy(() => import('./components/groupLoan/LoanActions'));
const OverdueEmis = lazy(() => import('./components/emis/OverdueEmis'));
const OverdueNoticePrint = lazy(() => import('./components/print/OverdueNoticePrint'));
const Cashbook = lazy(() => import('./components/reports/Cashbook'));
const LoanDeleteApproval = lazy(() => import('./components/approvals/LoanDeleteApproval'));
const PrintJointLiabilityForm = lazy(() => import('./components/print/PrintJointLiabilityForm'));
const LastEmiDepositReport = lazy(() => import('./components/reports/LastEmiDepositReport'));
const LoanTopup = lazy(() => import('./components/others/LoanTopup'));
const DatabaseBackup = lazy(() => import('./components/others/DatabaseBackup'));
const AllClosedLoanApplications = lazy(() => import('./components/groupLoan/AllClosedLoanApplications'));
const PartialEmiDeposit = lazy(() => import('./components/groupLoan/PartialEmiDeposit'));
const PartialEmiApproval = lazy(() => import('./components/approvals/PartialEmiApproval'));
const Registration = lazy(() => import('./components/users/Registration'));
const Login = lazy(() => import('./components/users/Login'));
const Member = lazy(() => import('./components/masterRecord/member/Member'));
const CloseAccount = lazy(() => import('./components/groupLoan/CloseAccount'));

// Main Routes Component
function Routes(props) {
    useEffect(() => {
        console.log("change event", props);
    }, []);

    return (
        <BrowserRouter>
            <Suspense fallback={<div>Loading...</div>}>
                <Switch>
                    <InnerLayout exact path={ROUTE.LANDINGPAGE} component={LandingPage} />
                    <InnerLayout exact path={ROUTE.DASHBOARD} component={Dashboard} />
                    <InnerLayout exact path={ROUTE.DASHBOARD_INVESTOR} component={InvestorDashboard} />
                    <InnerLayout exact path={ROUTE.MEMBER_GROUP} component={MemberGroup} />
                    <InnerLayout exact path={ROUTE.ADD_MEMBER_GROUP} component={AddMemberGroup} />
                    <InnerLayout exact path={ROUTE.ADD_MEMBER} component={AddMember} />
                    <InnerLayout exact path={ROUTE.SCHEME} component={Scheme} />
                    <InnerLayout exact path={ROUTE.VIEW_MEMBER_DETAIL} component={MemberViewDetails} />
                    <InnerLayout exact path={ROUTE.ADD_SCHEME} component={AddScheme} />
                    <InnerLayout exact path={ROUTE.LOANAPPLICATION} component={LoanApplication} />
                    <InnerLayout exact path={ROUTE.LOANAPPROVAL} component={LoanApproval} />
                    <InnerLayout exact path={ROUTE.DISBURSE_LOAN} component={DisburseLoan} />
                    <InnerLayout exact path={ROUTE.LOAN_APPROVAL_DETAILS} component={LoanApprovalDetails} />
                    <InnerLayout exact path={ROUTE.ALL_APPLICATIONS} component={AllApplications} />
                    <InnerLayout exact path={ROUTE.EMICALCULATOR} component={EmiCalculator} />
                    <InnerLayout exact path={ROUTE.DUE_EMIS} component={DueEmis} />
                    <InnerLayout exact path={ROUTE.PAID_DETAIL_VIEW} component={PaidDetailView} />
                    <InnerLayout exact path={ROUTE.RECIEVE_DETAIL_VIEW} component={RecievedDetailView} />
                    <InnerLayout exact path={ROUTE.PROCESSING_FEE} component={ProcessingFee} />
                    <InnerLayout exact path={ROUTE.PROFIT} component={Profit} />
                    <InnerLayout exact path={ROUTE.ADD_MEMBER_GROUP} component={AddProcessingFee} />
                    <InnerLayout exact path={ROUTE.OUTSTANDING_DETAIL_VIEW} component={OutstandingDetailView} />
                    <InnerLayout exact path={ROUTE.EXPENSE} component={Expense} />
                    <InnerLayout exact path={ROUTE.ADD_EXPENSE} component={AddExpense} />
                    <InnerLayout exact path={ROUTE.LIST_USER} component={ListUser} />
                    <InnerLayout exact path={ROUTE.ADD_USER} component={AddUser} />
                    <InnerLayout exact path={ROUTE.VILLAGE} component={Village} />
                    <InnerLayout exact path={ROUTE.ADD_VILLAGE} component={AddVillage} />
                    <InnerLayout exact path={ROUTE.MEMBER_VILLAGE_MAP} component={MemberVillageMap} />
                    <InnerLayout exact path={ROUTE.EMI_APPROVAL} component={EmiApproval} />
                    <InnerLayout exact path={ROUTE.EDIT_SCHEME} component={EditScheme} />
                    <InnerLayout exact path={ROUTE.EDIT_MEMBER} component={EditMember} />
                    <InnerLayout exact path={ROUTE.EDIT_MEMBER_GROUP} component={EditMemberGroup} />
                    <InnerLayout exact path={ROUTE.EDIT_EXPENSES} component={EditExpense} />
                    <InnerLayout exact path={ROUTE.EDIT_LOAN} component={EditLoanApplication} />
                    <InnerLayout exact path={ROUTE.EDIT_VILLAGE} component={EditVillage} />
                    <InnerLayout exact path={ROUTE.EDIT_PROCESSING_FEE} component={EditProcessingFee} />
                    <InnerLayout exact path={ROUTE.PRINT_LOAN} component={PrintLoan} />
                    <InnerLayout exact path={ROUTE.OPEN_SAVING_ACCOUNT} component={OpenSavingAccount} />
                    <InnerLayout exact path={ROUTE.SAVING_ACCOUNT_REPORT} component={SavingAccountReport} />
                    <InnerLayout exact path={ROUTE.SVAC_DEPOSITED_REPORT} component={SavingAccountDepositedReport} />
                    <InnerLayout exact path={ROUTE.ADD_TRANSACTION_SVAC} component={AddTransaction} />
                    <InnerLayout exact path={ROUTE.INVESTOR_DASHBOARD} component={AdminInvestorDashboard} />
                    <InnerLayout exact path={ROUTE.LOAN_CASH_AC_TRANSFER} component={LoanCashAcTransfer} />
                    <InnerLayout exact path={ROUTE.EMI_COLLECTION_SHEET} component={CollectionSheet} />
                    <InnerLayout exact path={ROUTE.CLOSE_GROUP_LOAN_ACCOUNT} component={CloseAccount} />
                    <InnerLayout exact path={ROUTE.MEMBER_APPROVAL} component={MemberApproval} />
                    <InnerLayout exact path={ROUTE.ADMIN_MAIN_LEDGER_DETAIL} component={AdminMainLedgerDetail} />
                    <InnerLayout exact path={ROUTE.PROFIT_LEDGER_DETAILS} component={ProfitLedgerDetails} />
                    <InnerLayout exact path={ROUTE.MAIN_LEDGER_DETAIL_INVESTOR} component={MainLedgerDetailInvestor} />
                    <InnerLayout exact path={ROUTE.LOAN_ACTIONS} component={LoanActions} />
                    <InnerLayout exact path={ROUTE.OVERDUES} component={OverdueEmis} />
                    <InnerLayout exact path={ROUTE.CASHBOOK} component={Cashbook} />
                    <InnerLayout exact path={ROUTE.LOAN_DELETION_APPROVAL} component={LoanDeleteApproval} />

                    <Route exact path={ROUTE.OVERDUE_NOTICE} component={OverdueNoticePrint} />
                    <Route path={ROUTE.LOGIN} component={Login} />
                    <Route path={ROUTE.DOWNLOAD_DOC} component={DownloadDoc} />
                    <Route path={ROUTE.PRINT_JOINT_LIABILITY} component={PrintJointLiabilityForm} />
                    <Route path={ROUTE.COMPANY_EXPENSE_SLIP_PDF} component={CompanyExpenseSlipPdf} />
                    <Route path={ROUTE.PASSBOOK} component={PassbookPdf} />
                    <Route path={ROUTE.PARTIAL_EMI_DEPOSIT} component={PartialEmiDeposit} />
                    <InnerLayout path={ROUTE.PARTIAL_EMI_APPROVAL} component={PartialEmiApproval} />
                    <InnerLayout path={ROUTE.REGISTRATION} component={Registration} />
                    <InnerLayout path={ROUTE.MEMBER} component={Member} />
                    <InnerLayout path={ROUTE.USER_MENUS} component={UserMenus} />
                    <InnerLayout path={ROUTE.LOAN_LAST_EMI_DEPOSIT_DIFF} component={LastEmiDepositReport} />
                    <InnerLayout path={ROUTE.LOAN_TOPUP} component={LoanTopup} />
                    <InnerLayout path={ROUTE.DATABASE_BACKUP} component={DatabaseBackup} />
                    <InnerLayout path={ROUTE.CLOSED_LOANS_LIST} component={AllClosedLoanApplications} />
                </Switch>
            </Suspense>
        </BrowserRouter>
    );
}

export default Routes;